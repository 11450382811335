import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

const Title = styled.h1`
  margin-top: 50px;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 33px;
  color: #474a4b;
  margin-bottom: 25px;
  padding: 10px 10%;
  text-align: center;
  @media only screen and (min-width: 768px) {
    font-size: 30px;
    padding: 20px 5%;
  }
  span {
    font-weight: 800;
  }
`;

const RegularP = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #474a4b;
`;

const ChatButton = styled.a`
  width: 260px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 24px;
  transition: all 0.3s ease;
  padding-top: 11px;
  @media only screen and (min-width: 768px) {
    width: 300px;
  }
`;

export const Doubts = (props) => {
  const number = "+525615077271";
  const location = useLocation();
  return (
    <div
      style={
        location.pathname === "/gluten/estudio/166"
          ? {
              background:
                "linear-gradient(159.18deg, #F3F3F3 11.5%, #F3F3F3 74.19%)",
            }
          : location.pathname === "/vg70/estudio/598"
          ? {
              background:
                "linear-gradient(159.18deg, #F3F3F3 11.5%, #F3F3F3 74.19%)",
            }
          : location.pathname === "/estudio/519"
          ? {
              background:
                "linear-gradient(159.18deg, #ffffff 11.5%, #ffffff 74.19%)",
            }
          : location.pathname === "/lactosa/estudio/590"
          ? {
              background:
                "linear-gradient(159.18deg, #F3F3F3 11.5%, #F3F3F3.19%)",
            }
          : location.pathname === "/cafeina/estudio/974"
          ? {
              background:
                "linear-gradient(159.18deg, #F3F3F3 11.5%, #F3F3F3 74.19%)",
            }
          : null
      }
    >
      <Container style={{ paddingBottom: "42px" }}>
        <Title>¿Tienes dudas?</Title>
        <RegularP>
          Nuestro equipo está capacitado para responder cualquier duda y darte
          la asistencia necesaria. Responde al siguiente formulario.
        </RegularP>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ChatButton
            style={
              location.pathname === "/gluten/estudio/166"
                ? {
                    background: "#F3F3F3",
                    color: "#FF8E3D",
                    border: "2px solid #FF8E3D",
                    backgroundClip: "padding-box, border-box",
                    backgroundOrigin: "padding-box, border-box",
                  }
                : location.pathname === "/vg70/estudio/598"
                ? {
                    background: "#F3F3F3",
                    color: "#F14CAE",
                    border: "2px solid #F14CAE",
                    backgroundClip: "padding-box, border-box",
                    backgroundOrigin: "padding-box, border-box",
                  }
                : location.pathname === "/estudio/519"
                ? {
                    backgroundImage:
                      "linear-gradient(45deg, #fff, #fff),linear-gradient(180deg, #5731B2 -1.87%, #77CCD5 151.21%)",
                    color: "#5731B2",
                    border: "2px solid transparent",
                    backgroundClip: "padding-box, border-box",
                    backgroundOrigin: "padding-box, border-box",
                  }
                : location.pathname === "/lactosa/estudio/590"
                ? {
                    background:"#F3F3F3",
                    color: "rgba(0, 180, 187, 0.6)",
                    border: "2px solid rgba(0, 180, 187, 0.6)",
                    backgroundClip: "padding-box, border-box",
                    backgroundOrigin: "padding-box, border-box",
                  }
                : location.pathname === "/cafeina/estudio/974"
                ? {
                    background:
                      "#F3F3F3",
                    color: "#24CAD3",
                    border: "2px solid #24CAD3",
                    backgroundClip: "padding-box, border-box",
                    backgroundOrigin: "padding-box, border-box",
                  }
                : location.pathname === "/aliadosvitales"
                ? {
                    backgroundImage:
                      "linear-gradient(45deg, #fff, #fff),linear-gradient(180deg, #A62C77 0%, #00B8C3 100%)",
                    color: "#A62C77",
                    border: "2px solid transparent",
                    backgroundClip: "padding-box, border-box",
                    backgroundOrigin: "padding-box, border-box",
                  }
                : null
            }
            href={`https://wa.me/${number}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Chatea con nosotros
          </ChatButton>
        </div>
        <RegularP>
          Al continuar se abrirá una ventana para iniciar una conversación de
          Whatsapp
        </RegularP>
      </Container>
    </div>
  );
};
