import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import {
  UilInvoice,
  UilCreditCard,
  UilMasterCard,
} from '@iconscout/react-unicons';
import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from '@stripe/react-stripe-js';
import { Loader } from '../general/spinner';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastSweet } from '../../helpers/sweetAlert2';
import { useForm } from 'react-hook-form';
// const API = 'http://localhost:3010';

const STRIPE_ERROR = 'Error al pagar.Inténtalo otra vez.';
const SERVER_ERROR = 'Error de servidor. Inténtalo otra vez.';
const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51IVo6uJ0MPtwgs6E6ZAY2ylt0KrAqI1A5YgXdancBmk2G0Dh0NAotwBnWwHyVxV12xU5cMcGiZrShhgRlvlThJjl00jE5H3Smt';
  // Test Key
  // 'pk_test_51IVo6uJ0MPtwgs6EVAgG88VBkdu8qLQff1OUY24IofLEPTuIPysciOqqaKBgKyDjHipKbBvhUcmcs44MBK2BaVck008g5c2uQY';

const StyledForDesktop = styled.div`
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const SectionTitle = styled.p`
  font-family: 'Assistant', sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: #02a1b5;
  margin: 0;
  padding: 0;
`;

const StyledCheck = styled(Form.Check)`
  padding-left: 1.5em;
  max-width: 240px;
  width: 100% !important;
  .form-check-label {
    width: auto;
  }
  .form-check-input[type='checkbox'] {
    margin-left: -1.5em;
    float: left;
  }
  .form-check-input[type='checkbox'],
  .form-check-input[type='radio'] {
    margin-left: -1.5em;
  }
  label {
    padding-left: 1%;
  }
  > input {
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #02a1b5;
    :checked {
      border: 1px solid #02a1b5;
      background-color: #02a1b5;
    }
  }
`;

const StyledLabel = styled(FloatingLabel)`
  width: 100%;
  padding: 0;
  .form-control {
    border: none;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    font-family: 'Assistant', sans-serif;
    max-height: 45px;
    padding: 0 0 0 40px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
    :disabled {
      background-color: #f6f6f6;
      border: 1px solid #cccccc;
      border-style: dashed;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
  .form-select {
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    font-family: 'Assistant', sans-serif;
    max-height: 45px;
    padding: 0 0 0 40px !important;
    box-shadow: 0px 4px 4px #f6f6f6;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237a4dc7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :disabled {
      background-color: #f6f6f6;
      border: 1px solid #cccccc;
      border-style: dashed;
      color: #b8b8b8;
    }
  }
  label {
    font-weight: normal;
    font-size: 16px;
    color: ${(props) => (props.disabled ? '#b8b8b8' : '#828282')};
    transform-origin: -1em -10em;
    border-radius: 4px;
    padding: 3px 10px;
    margin: 5px 0 0 40px !important;
    height: auto;
    font-family: 'Assistant', sans-serif;
    transition: 300ms;
  }
  :active,
  :focus-within {
    label {
      background-color: ${(props) => (props.disabled ? '#F6F6F6' : '#ffffff')};
      margin: 5px 0 0 35px;
      padding: 3px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledCvv = styled(StyledLabel)`
  label {
    margin: 5px 0 0 20px !important;
    transform-origin: -4em -10em !important;
  }
`;

const RegularP = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const InvertedRadius = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 328px;
  @media only screen and (min-width: 769px) {
    width: 100%;
  }
  padding: 4%;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 10px 20px rgba(164, 164, 164, 0.5);
  > div.bottom {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
  }
  > .top {
    top: 12px;
  }
  > .bottom {
    bottom: 64px;
  }
  > .left {
    left: -16px;
  }
  > .right {
    right: -16px;
  }
  > hr.pointed {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    height: 0;
    border: medium none;
    border-top: 2px dashed #e0ecec;
  }
  > div div.col-3 svg {
    width: 30px !important;
    height: 30px !important;
  }
`;

const CardDate = styled(Row)`
  position: relative;
  padding: 0;
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
    // background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%;
  }

  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;

const PayButton = styled(Button)`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: rgba(2, 161, 181, 0.81);
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: rgba(2, 161, 181, 0.81);
  }
  :hover {
    background: rgba(0, 139, 157, 0.81);
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const ErrorMessage = styled.p`
  width: auto;
  margin: 0;
  font-family: 'Assistant', sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  color: #ff0505;
`;

const VerifyPlansBtn = styled.button`
  border-radius: 102px;
  outline: none;
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  max-width: 360px;
  width: 100%;
  background-color: #ffffff;
  border: 2px solid #77ccd5;
  color: #77ccd5;
  :disabled {
    border: 1px solid #d9d9d9;
    color: #d9d9d9;
  }
`;

export const StepFour = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [inputValue, setInputValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState(0);
  const [isInvalid, setIsInvalid] = useState(true);
  const [plan, setPlan] = useState();
  const [policies, setPolicies] = useState(false);
  const [consent, setConsent] = useState(false);
  const [total, setTotal] = useState(props.subTotal);
  const items = props.items;
  const [msiItems, setMsiItems] = useState([]);
  const [normalItems, setNormalItems] = useState([]);
  const idCart = useState(1); //It was useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }

  const showErrorToast = (message) => {
    toast.error(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccessToast = (message) => {
    toast.success(message, {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setInputValue({ ...inputValue, [name]: value });
  };

  const getInputValue = (e) => {
    const name = e;
    return inputValue[name] || '';
  };

  const getCreditcardToken = (creditCardData) => {
    const card = {
      'card[number]': creditCardData.number.replace(/ /g, ''),
      'card[exp_month]': creditCardData.month,
      'card[exp_year]': creditCardData.year,
      'card[cvc]': creditCardData.cvc,
    };
    return fetch('https://api.stripe.com/v1/tokens', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${STRIPE_PUBLISHABLE_KEY}`,
      },
      method: 'post',
      body: Object.keys(card)
        .map((key) => key + '=' + card[key])
        .join('&'),
    }).then((response) => response.json());
  };

  const createPaymentMethod = async (data) => {
    const card = {
      number: data.number.replace(/ /g, ''),
      exp_month: data.month,
      exp_year: data.year,
      cvc: data.cvc,
    };
    // return await fetch('http://localhost:3010/payment-method', {
    return await fetch('https://labopat-5792e.appspot.com/payment-method', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(card),
    }).then(async (response) => {
      let res = await response.json();
      return res;
    }).catch((error) => {
      console.log(error)
      toastSweet('error', 'Ha ocurrido un error al realizar la consulta', 2500);
      setLoading(false);
      return;
    });
  };

  const checkAvailablePlans = async () => {
    if(isInvalid) {
      alert('Por favor, completa los campos requeridos');
    } else {
      setLoading(true);
      const data = {
        number: inputValue['cardNumber'].replace(/ /g, ''),
        month: inputValue['month'],
        year: inputValue['year'],
        cvc: inputValue['cvc'],
        amount: props.subTotal,
      };
      let paymentMethodId = await createPaymentMethod(data);
      let amount = data.amount * 100;
      if (paymentMethodId.error) {
        toastSweet('warning', `${paymentMethodId.error}`, 3000);
        setLoading(false);
      } else {
        // await fetch('http://localhost:3010/card-plans', {
        await fetch('https://labopat-5792e.appspot.com/card-plans', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: amount,
            payment_method_id: paymentMethodId.paymentMethod_id,
            description: props.personalData.name,
            email: props.personalData.email,
          }),
        }).then(async (response) => {
          let res = await response.json();
          if (res.error) {
            toastSweet('warning', `${res.error}`, 3000);
            setLoading(false);
            return;
          } else {
            const msi = [3];
            res = {...res, available_plans: res.available_plans.filter((plan) => msi.includes(plan.count))};
            setPlan(res);
            setLoading(false);
            return;
          }
        }).catch((error) => {
          console.log(error);
          toastSweet('error', 'Ha ocurrido un error al consultar las promociones', 2500);
          setLoading(false);
          return;
        });
      }
    }
  };

  const confirmPayment = async (data) => {
    try {
      // const response = await fetch('http://localhost:3010/confirm-payment', {
      const response = await fetch('https://labopat-5792e.appspot.com/confirm-payment', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          payment_intent_id: data.payment_intent_id,
          selected_plan: data.selected_plan,
        }),
      });
      let json = await response.json();
      if(json.error) {
        toastSweet('warning', `${json.error}`, 3000);
        setLoading(false);
        return;
      } else {
        setLoading(false);
        props.onStepFour({data: json});
      }
    } catch (error) {
      console.log(error);
      toastSweet('error', 'Hubo un error al confirmar su pago', 2500);
      setLoading(false);
    }
  }

  const handleSubmitPay = async (e) => {
    setLoading(true);
    window.scroll({
      top: 0,
    });
    let creditCardToken;
    try {
      // Create a credit card token
      creditCardToken = await getCreditcardToken({
        number: e.cardNumber,
        month: e.month,
        year: e.year,
        cvc: e.cvc,
      });
      if (creditCardToken.error) {
        // Reset the state if Stripe responds with an error
        // Set submitted to false to let the user subscribe again
        console.log('error ---<> ', creditCardToken.error);
        showErrorToast(creditCardToken.error.message);
        await setLoading(false);
        //this.setState({ submitted: false, error: STRIPE_ERROR });
        return;
      }
    } catch (e) {
      // Reset the state if the request was sent with an error
      // Set submitted to false to let the user subscribe again
      console.group('error --> ', e);
      await setLoading(false);
      //this.setState({ submitted: false, error: STRIPE_ERROR });
      return;
    }
    if(inputValue['selectedPlan'] !== undefined) {
      if (inputValue['selectedPlan'] === 'Un solo pago') {
        doPayment({ amount: props.subTotal * 100, token: creditCardToken.id });
      } else {
        confirmPayment({payment_intent_id: plan.intent_id, selected_plan: plan.available_plans.filter((plan) => plan.count === Number(inputValue['selectedPlan']))[0]});
      }
    } else {
      doPayment({ amount: props.subTotal * 100, token: creditCardToken.id });
    };
    // Test:
    // doPayment({ amount: 25, token: creditCardToken.id });
  };

  const doPayment = async (data) => {
    let request = axios.get(
      // 'http://localhost:3010/vg-payment?psw=Gwi&amount=' +
      'https://labopat-5792e.appspot.com/vg-payment?psw=Gwi&amount=' +
      data.amount +
      '&source=' +
      data.token +
      '&description=' +
      props.personalData.name +
      '&uid=uid&email_cust=email@email.com&mail=' +
      props.personalData.email
    );
    let response = await request;
    await setLoading(false);
    props.onStepFour(response);
  };

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (
      inputValue['cardNumber'] &&
      inputValue['month'] &&
      inputValue['year'] &&
      inputValue['cvc']
    ) {
      inputValue['cardNumber'].length >= 15 &&
      inputValue['cardNumber'].length <= 16 &&
      inputValue['month'].length >= 1 &&
      inputValue['month'].length <= 2 &&
      inputValue['year'].length === 4 &&
      inputValue['cvc'].length >= 3 &&
      inputValue['cvc'].length <= 4
        ? setIsInvalid(false)
        : setIsInvalid(true);
    } else {
      setIsInvalid(true);
    }
  }, [inputValue]);

  useEffect(() => {
    setPlan();
    reset();
    setInputValue({});
    setPolicies(false);
    setConsent(false);
  }, [method]);

  useEffect(() => {
    let withMsi = [];
    let withoutMsi = [];
    items.forEach((element) => {
      if (element.msi === 1) {
        withMsi.push(element);
      } else {
        withoutMsi.push(element);
      }
    });
    setMsiItems(withMsi);
    setNormalItems(withoutMsi);
  }, [items]);

  return (
    <Container className='pt-5 pb-5'>
      <StyledForDesktop style={screenWidth <= 768 ? { maxWidth: '100%' } : {}}>
        <Row className='justify-content-center'>
          <Col xs={12} sm={12} md={10} lg={8}>
            <Row className='mb-4'>
              <SectionTitle>1. Método de pago</SectionTitle>
            </Row>
            <Row>
              <StyledCheck
                type='radio'
                id='creditCardRadio'
                className='mb-3 w-auto'
              >
                <Form.Check.Input
                  type='radio'
                  checked={method === 0 ? true : false}
                  onChange={() => setMethod(0)}
                />
                <Form.Check.Label>
                  <SectionTitle style={{ color: '#000000' }}>
                    Pago con tarjeta de crédito
                  </SectionTitle>
                </Form.Check.Label>
              </StyledCheck>
              <StyledCheck
                type='radio'
                id='creditCardRadio'
                className='mb-5 w-auto'
              >
                <Form.Check.Input
                  type='radio'
                  checked={method === 1 ? true : false}
                  onChange={() => setMethod(1)}
                />
                <Form.Check.Label>
                  <SectionTitle style={{ color: '#000000' }}>
                    Pago con tarjeta de débito
                  </SectionTitle>
                </Form.Check.Label>
              </StyledCheck>
              {(method === 0 || method === 1) && (
                <>
                  <Form
                    className='p-0'
                    onSubmit={handleSubmit(handleSubmitPay)}
                  >
                    <Row className='mb-4 pb-2'>
                      <StyledLabel
                        controlId='cardNumber'
                        label='Número de tarjeta'
                        style={{ position: 'relative' }}
                      >
                        <UilMasterCard
                          className='mt-auto mb-auto w-auto'
                          style={{
                            position: 'absolute',
                            left: '14px',
                            top: 0,
                            bottom: 0,
                          }}
                        />
                        <Form.Control
                          type='number'
                          name='cardNumber'
                          placeholder='Número de tarjeta'
                          {...register('cardNumber', {
                            required: 'Campo requerido',
                            minLength: 16,
                            maxLength: 16,
                            pattern: {
                              value: /[0-9]/i,
                            },
                          })}
                          value={getInputValue('cardNumber')}
                          onChange={(e) => handleInput(e)}
                          style={{ paddingLeft: '50px' }}
                        />
                      </StyledLabel>
                      {errors?.cardNumber ? (
                        <ErrorMessage className='pt-2'>
                          {errors.cardNumber?.message}
                        </ErrorMessage>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <Row className='align-items-center justify-content-center mb-4 pb-2'>
                      <RegularP className='mb-3 p-0'>
                        Fecha de expiración
                      </RegularP>
                      <CardDate>
                        <Col
                          xs={6}
                          style={{
                            paddingLeft: '0px',
                            paddingRight: '8px',
                            zIndex: '1',
                          }}
                        >
                          <Form.Label>Mes</Form.Label>
                          <Form.Select
                            name='month'
                            {...register('month', {
                              required: 'Selecciona una mes',
                              pattern: {
                                value: /[0-9]/i,
                                message: 'Selecciona un mes',
                              },
                            })}
                            value={getInputValue('month')}
                            onChange={(e) => handleInput(e)}
                          >
                            <option hidden>MM</option>
                            <option value={1}>01</option>
                            <option value={2}>02</option>
                            <option value={3}>03</option>
                            <option value={4}>04</option>
                            <option value={5}>05</option>
                            <option value={6}>06</option>
                            <option value={7}>07</option>
                            <option value={8}>08</option>
                            <option value={9}>09</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </Form.Select>
                        </Col>
                        <p
                          style={{
                            position: 'absolute',
                            bottom: '-10px',
                            top: 0,
                            left: 0,
                            right: 0,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: 0,
                            textAlign: 'center',
                            width: 'auto',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          /
                        </p>
                        <Col
                          xs={6}
                          style={{
                            paddingLeft: '8px',
                            paddingRight: '0px',
                            zIndex: '1',
                          }}
                        >
                          <Form.Label>Año</Form.Label>
                          <Form.Select
                            name='year'
                            {...register('year', {
                              required: 'Selecciona un año',
                              pattern: {
                                value: /[0-9]/i,
                                message: 'Selecciona un año',
                              },
                            })}
                            value={getInputValue('year')}
                            onChange={(e) => handleInput(e)}
                          >
                            <option hidden>YYYY</option>
                            <option value={2022}>2022</option>
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                            <option value={2025}>2025</option>
                            <option value={2026}>2026</option>
                            <option value={2027}>2027</option>
                            <option value={2028}>2028</option>
                            <option value={2029}>2029</option>
                            <option value={2030}>2030</option>
                            <option value={2031}>2031</option>
                            <option value={2032}>2032</option>
                            <option value={2033}>2033</option>
                          </Form.Select>
                        </Col>
                      </CardDate>
                      <Row>
                        <Col
                          xs={6}
                          style={{
                            paddingLeft: '0px',
                            paddingRight: '8px',
                            zIndex: '1',
                          }}
                        >
                          {errors?.month ? (
                            <ErrorMessage className='pt-2'>
                              {errors.month?.message}
                            </ErrorMessage>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col
                          xs={6}
                          style={{
                            paddingLeft: '0px',
                            paddingRight: '8px',
                            zIndex: '1',
                          }}
                        >
                          {errors?.year ? (
                            <ErrorMessage className='pt-2'>
                              {errors.year?.message}
                            </ErrorMessage>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Row>
                    <Row className='mb-4'>
                      <RegularP className='p-0 mb-4'>Número CVV</RegularP>
                      <StyledCvv
                        controlId='cvc'
                        label='CVV'
                        className='cvvLabel'
                        style={{ position: 'relative' }}
                      >
                        <Form.Control
                          type='password'
                          name='cvc'
                          placeholder='CVV'
                          {...register('cvc', {
                            required: 'Campo requerido',
                            minLength: 3,
                            maxLength: 3,
                            pattern: {
                              value: /[0-9]/i,
                              message: 'Solo números',
                            },
                          })}
                          value={getInputValue('cvc')}
                          onChange={(e) => handleInput(e)}
                          autoComplete='off'
                        />
                        <UilCreditCard
                          style={{
                            position: 'absolute',
                            right: '28px',
                            bottom: '0',
                            top: '0',
                            color: '#A6A6A6',
                          }}
                          className='mt-auto mb-auto'
                        />
                      </StyledCvv>
                      {errors?.cvc ? (
                        <ErrorMessage className='pt-2'>
                          {errors.cvc?.message}
                        </ErrorMessage>
                      ) : (
                        <></>
                      )}
                    </Row>
                    {method === 0 && msiItems.length > 0 && (
                      <Row className='justify-content-center mb-4'>
                        <VerifyPlansBtn disabled={isInvalid} type='button' onClick={() => checkAvailablePlans()}>
                          Revisar promociones disponibles
                        </VerifyPlansBtn>
                      </Row>
                    )}
                    {plan && (
                      <>
                        {plan?.available_plans?.length > 0 ? (
                          <CardDate className='justify-content-center mb-4'>
                            <div className='p-0'>
                              <RegularP className='mb-4'>Elige tu plan de pagos</RegularP>
                              <Form.Select
                                name='selectedPlan'
                                value={getInputValue('selectedPlan')}
                                onChange={(e) => handleInput(e)}
                              >
                                <option hidden>Selecciona un plan</option>
                                <option value={'Un solo pago'}>Un solo pago</option>
                                {plan?.available_plans?.map((plan, index) => (
                                  <option key={index} value={plan?.count}>{plan?.count} meses sin intereses</option>
                                ))}
                              </Form.Select>
                            </div>
                          </CardDate>
                        ) : (
                          <>
                            <Row className='mb-4 justify-content-center'>
                              <RegularP className='m-0 text-center'>
                                No hay promociones disponibles para esta tarjeta
                              </RegularP>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                    <Row className='mb-3'>
                      <hr />
                    </Row>
                    <Row className='mb-4'>
                      <StyledCheck className='mb-2 mw-100' type='checkbox' controlId='policies'>
                        <StyledCheck.Input
                          type='checkbox'
                          name='policies'
                          checked={policies}
                          onChange={() => setPolicies(!policies)}
                        />
                        <StyledCheck.Label>
                          <a
                            className='mb-0'
                            style={{
                              color: '#000000',
                              textDecoration: 'underline',
                            }}
                            href='https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/Te%CC%81rminos%20y%20condiciones.pdf?alt=media&token=45351972-b591-46ab-882d-955b79a38169'
                            target={'_blank'}
                            rel='noreferrer'
                          >
                            Aceptar términos y condiciones
                          </a>
                        </StyledCheck.Label>
                      </StyledCheck>
                      <StyledCheck type='checkbox' className='mw-100' controlId='consent'>
                        <StyledCheck.Input
                          type='checkbox'
                          name='consent'
                          checked={consent}
                          onChange={() => setConsent(!consent)}
                        />
                        <StyledCheck.Label>
                          <a
                            className='mb-0'
                            style={{
                              color: '#000000',
                              textDecoration: 'underline',
                            }}
                            href='https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/Consentimiento%20informado.pdf?alt=media&token=b8fed55c-a5ef-422b-b6d1-b4e2d7cda1b5'
                            target={'_blank'}
                            rel='noreferrer'
                          >
                            Consentimiento informado
                          </a>
                        </StyledCheck.Label>
                      </StyledCheck>
                    </Row>
                    <Row className='justify-content-center mb-4'>
                      <PayButton
                        type='submit'
                        disabled={policies && !consent === false ? false : true}
                      >
                        Pagar
                      </PayButton>
                    </Row>
                  </Form>
                </>
              )}
            </Row>
            <Row className='mb-4 pb-2'>
              <InvertedRadius>
                <p
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#3B3B3B',
                  }}
                >
                  Resumen de la solicitud
                </p>
                <table className='w-100'>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={index}>
                        <th>
                          <p style={{ fontWeight: '500', marginBottom: '8px' }}>
                            {item.title}
                          </p>
                        </th>
                        <th>
                          <p style={{ marginBottom: '8px' }}>
                          {props.items.some(e => e.id === 675) ? 
                            <p>$2000 MXN x 3 meses</p> 
                            : 
                            <p>{currency(item.price)} MXN</p>
                          }
                          </p>
                        </th>
                      </tr>
                    ))}
                    {props.isExpress && props.items.some(e => e.id !== 675 && e.id !== 966) && (
                      <tr>
                        <th>
                          <p style={{ fontWeight: '500', marginBottom: '8px' }}>
                            Envío
                          </p>
                        </th>
                        <th>$350 MXN</th>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div>
                  <hr />
                </div>
                {props.discount !== 0 && (
                  <div>
                    <p>
                      Usaste el cupón "
                      <span style={{ fontWeight: '800' }}>{props.coupon}</span>"
                    </p>
                    <p>
                      Obtuviste un descuento de{' '}
                      <span style={{ fontWeight: '800' }}>
                        {currency(props.discount)} MXN
                      </span>
                    </p>
                    <hr />
                  </div>
                )}
                <Row>
                  <Col xs={12} style={{ textAlign: 'center' }}>
                    <p
                      style={{
                        fontWeight: '800',
                        marginBottom: '0px',
                        fontSize: '16px',
                      }}
                    >
                      Total a pagar:
                    </p>
                    <p style={{ fontSize: '16px', marginBottom: '0px' }}>
                      {currency(total)} MXN
                    </p>
                  </Col>
                </Row>
              </InvertedRadius>
            </Row>
            {loading ? <Loader show={loading} /> : null}
          </Col>
        </Row>
      </StyledForDesktop>
    </Container>
  );
};
