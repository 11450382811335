// Routing
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useLocation,
} from "react-router-dom";
import { login } from "../actions/auth";
import { NavBar } from "../components/navbar/navbar";
// Components
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

import { Buscador } from "../containers/Buscador";
import { Main } from "../containers/categories/Main";
import { Studies } from "../containers/categories/Studies";
import { Checkout } from "../components/checkout/Checkout";
import { CheckoutVG } from "../components/checkoutVG/Checkout";
import { Contact } from "../containers/Contact";
import { Home } from "../containers/home";
// import { PaquetesMujer } from '../containers/paquetesMujer';
import { Register } from "../containers/Register";
import { Login } from "../containers/Login";
import loading from "../assets/logos/vitalgene.gif";
import { Dashboard } from "../components/dashboard/Dashboard";
import { PersonalData } from "../components/profile-menu/PersonalData";
import { Password } from "../containers/Password";
import { Profile } from "../containers/Profile";
import { RegisterPersonalData } from "../components/login/RegisterPersonalData";
import { Spinner } from "react-bootstrap";
import { getUserInfo, userInfo } from "../actions/user";
import { ShoppingTest } from "../components/shopping-cart/ShoppingTest";
import { MedicalRecord } from "../components/profile-menu/MedicalRecord";

import { EditPersonalData } from "../components/profile-menu/EditPersonalData";
// import { Indications } from '../components/profile-menu/Indications';
// import { RecordDetail } from '../components/profile-menu/RecordDetail';
import { Footer } from "../components/footer/Footer";
import { StudyDetail } from "../components/studies/studyDetail";
import { checkCart } from "../actions/shoppingCart";
import { Branches } from "../components/branches/Branches";
import { Doctors } from "../components/doctors/Doctors";
import { AboutUs } from "../components/aboutUs/aboutUs";
import { FAQs } from "../components/FAQs/FAQs";
import { Carrier } from "../components/carrier/Carrier";
import { Certifications } from "../components/certifications/Certifications";
import { Covid } from "../components/covid/Covid";
import { HowItWorks } from "../components/howItWorks/HowItWorks";
import { Course } from "../components/course/Course";
import { AboutVitalgene } from "../components/aboutVitalgene/AboutVitalgene";
import { Professionals } from "../components/professionals/Professionals";
import { Kit } from "../components/kit/kit";

import { MainCheckin } from "../components/check-in/Main";
// import { Packs } from '../components/packages/Packs';
// import { MyPackages } from '../components/profile-menu/MyPackages';

import { CompleteAppointment } from "../components/complete-appointment/CompleteAppointment";
import { PrivRoutes } from "./routes";
import { RootContainer } from "../components/root/rootContainer";
import { SideNavContainer } from "../components/root/sideNavContainer";
import { SideNav } from "../components/sidenav/SideNav";
import { FloatingWhatsapp } from "../components/root/whatsappContact";
import { ProgramaTransforma } from "../components/programa-transforma/ProgramaTransforma";
import { stuGluten } from "../components/content/home/gluten/stuGluten";
import { stuLactosa } from "../components/content/home/lactosa/stuLactosa";
import { stuCafeina } from "../components/content/home/cafeina/stuCafeina";
import { SecVg } from "../components/content/home/vg/secVg";
import { NavSport } from "../components/content/home/sport/navSport";
import { QuizGluten } from "../components/content/home/gluten/quizGluten";
import { ResultadoBajoGluten } from "../components/content/home/gluten/resultadoBajo";
import { ResultadoAltoGluten } from "../components/content/home/gluten/resultadoAlto";
import { QuizLactosa } from "../components/content/home/lactosa/quizLactosa";
import { ResultadoBajoLactosa } from "../components/content/home/lactosa/resultadoBajo";
import { ResultadoAltoLactosa } from "../components/content/home/lactosa/resultadoAlto";
import { QuizCafeina } from "../components/content/home/cafeina/quizCafeina";
import { ResultadoBajoCafeina } from "../components/content/home/cafeina/resultadoBajo";
import { ResultadoAltoCafeina } from "../components/content/home/cafeina/resultadoAlto";
import { QuizVg70 } from "../components/content/home/vg/quizVg70";
import { ResultadoBajoVg70 } from "../components/content/home/vg/resultadoBajo";
import { ResultadoAltoVg70 } from "../components/content/home/vg/resultadoAlto";
import TrackOrder from "../components/dashboard/trackbar";
import SectionsAliados from "../components/content/home/aliados/sectionsaliados";
import { Invitacion } from "../components/content/home/aliados/invitacion";
import { Invitaciones } from "../components/content/home/aliados/formaliados/invitaciones";
import AliadosVG from "../components/content/home/aliados/formaliados/aliadosVG";


export const AppRouter = () => {
  const dispatch = useDispatch();
  // const { items } = useSelector(state => state.shoppingCart);
  const [checking, setsChecking] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user?.uid) {
        dispatch(login(user.uid, user.displayName));
        setIsLoggedIn(true);
        dispatch(userInfo(await getUserInfo(user?.uid)));
      } else {
        setIsLoggedIn(false);
      }

      setsChecking(false);
    });
    dispatch(checkCart());
  }, [dispatch, setsChecking]);

  useEffect(() => {
    PrivRoutes.includes(location.pathname)
      ? setShowNav(false)
      : setShowNav(true);
  }, [location]);

  if (checking) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <img src={loading} alt="loading" width={300} />
      </div>
    );
  }

  return (
    <RootContainer showNav={showNav ? 0 : 1}>
      <FloatingWhatsapp />
      {showNav ? (
        <NavBar showCart={showCart} setShowCart={(e) => setShowCart(e)} />
      ) : (
        <SideNavContainer>
          <SideNav />
        </SideNavContainer>
      )}
      <div style={showNav ? {} : { flex: 1, flexDirection: "column" }}>
        <Switch>
          <Route exact path="/">
            <Home setShowCart={(e) => setShowCart(e)} />
          </Route>
          <Route path="/como-funciona" component={HowItWorks} />
          <Route path="/curso" component={Course} />
          <Route path="/descubre" component={AboutVitalgene} />
          <Route path="/profesionistas" component={Professionals} />
          <Route path="/carrito" component={ShoppingTest} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/checkoutVG" component={CheckoutVG} />
          <Route path="/estudios" component={Studies} />
          <Route path="/sucursales" component={Branches} />
          <Route path="/medicos" component={Doctors} />
          <Route path="/nosotros" component={AboutUs} />
          <Route path="/preguntas-frecuentes" component={FAQs} />
          <Route path="/carrera" component={Carrier} />
          <Route path="/certificaciones" component={Certifications} />
          <Route path="/preguntas-frecuentes" component={FAQs} />
          <Route path="/covid" component={Covid} />
          <Route path="/estudio/:studyId" component={StudyDetail} />
          <Route path="/buscador" component={Buscador} />
          <Route path="/completar-cita" component={CompleteAppointment} />
          <Route path="/programa-transforma">
            <ProgramaTransforma setShowCart={(e) => setShowCart(e)} />
          </Route>
          <Route path="/resultados" />
          <Route path="/contacto" component={Contact} />
          <Route path="/password" component={Password} />
          <Route path="/gluten" component={stuGluten} />
          <Route path="/lactosa" component={stuLactosa} />
          <Route path="/cafeina" component={stuCafeina} />
          <Route path="/vg70" component={SecVg} />
          <Route path="/sport" component={NavSport} />
          <Route path="/quizGluten" component={QuizGluten} />
          <Route path="/resultadoGlutenBajo" component={ResultadoBajoGluten} />
          <Route path="/resultadoGlutenAlto" component={ResultadoAltoGluten} />
          <Route path="/quizLactosa" component={QuizLactosa} />
          <Route
            path="/resultadoLactosaBajo"
            component={ResultadoBajoLactosa}
          />
          <Route
            path="/resultadoLactosaAlto"
            component={ResultadoAltoLactosa}
          />
          <Route path="/quizCafeina" component={QuizCafeina} />
          <Route
            path="/resultadoCafeinaBajo"
            component={ResultadoBajoCafeina}
          />
          <Route
            path="/resultadoCafeinaAlto"
            component={ResultadoAltoCafeina}
          />
          <Route path="/quizVg70" component={QuizVg70} />
          <Route path="/resultadoVg70Bajo" component={ResultadoBajoVg70} />
          <Route path="/resultadovg70Alto" component={ResultadoAltoVg70} />
          <Route path="/trackorder/:id" component={TrackOrder} />
          <Route path="/aliadosvitales" component={SectionsAliados} />
          <Route path="/contactoaliados" component={AliadosVG}/>
          <Route path="/invitacion" component={Invitacion} />
          <Route path="/invitaciones" component={Invitaciones} />
          <Route path="/checkin" component={MainCheckin} />

          <PublicRoute
            path="/registro-datos-personales"
            isAuthenticated={isLoggedIn}
            component={RegisterPersonalData}
          />

          <PublicRoute
            path="/registro"
            isAuthenticated={isLoggedIn}
            component={Register}
          />

          <PublicRoute
            path="/iniciar-sesion"
            isAuthenticated={isLoggedIn}
            component={Login}
          />

          <PrivateRoute
            exact
            isAuthenticated={isLoggedIn}
            path="/perfil"
            component={Profile}
          >
            <Redirect to="/mi-perfil" />
          </PrivateRoute>

          <PrivateRoute
            exact
            isAuthenticated={isLoggedIn}
            path="/mi-perfil"
            component={EditPersonalData}
          />

          <PrivateRoute
            exact
            isAuthenticated={isLoggedIn}
            path="/trackorder"
            component={TrackOrder}
          />

          <PrivateRoute
            exact
            isAuthenticated={isLoggedIn}
            path="/dashboard"
            component={Dashboard}
          />

          <PrivateRoute
            path="/historial-pruebas"
            isAuthenticated={isLoggedIn}
            component={MedicalRecord}
          />

          <PrivateRoute
            path="/activar-kit"
            isAuthenticated={isLoggedIn}
            component={Kit}
          />

          <Route path="/:service" component={Main} />
          <Route path="*">
            <Home setShowCart={(e) => setShowCart(e)} />
          </Route>
          <Redirect to="/" />
        </Switch>
        <Footer onDashboard={showNav === false ? 1 : 0} />
      </div>
    </RootContainer>
  );
};
