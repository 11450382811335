import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StepFour } from "./stepFour";
import { StepThree } from "./stepThree";
import { StepTwo } from "./stepTwo";
import { ThanksScreen } from "./thanksScreen";
import { ThanksScreenCom } from "./thanksScreenCom";
import { Loader } from "../general/spinner";
import {
  UilClipboardNotes,
  UilClipboardAlt,
  UilCalendarAlt,
  UilUser,
  UilTruck,
} from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import axios from "axios";
import { FirstTimeDoctor } from "./firstTimeDoctor";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";

const Background = styled.div`
  background: #f1f8f9;
  background-size: 100vw 100vh;
  background-attachment: fixed;
  @media only screen and (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  > .container {
    background: #fff;
    max-width: 1120px !important;
    @media only screen and (min-width: 768px) {
      border-radius: 16px;
      padding-top: 24px;
      width: 60%;
    }
  }
`;

const Progress = styled.div`
  position: sticky;
  z-index: 5;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid #ebebeb;
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 0px;
  }
  h2 {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #8055ad;
    border: 4px solid #fff;
    margin-left: auto;
    margin-right: auto;
  }
  .unavailable {
    .circle {
      background: #fff;
    }
    h2 {
      color: #afafaf;
    }
  }
  .current .circle {
    background: #f9ad62;
  }
`;

const TextSteps = styled.p`
  margin-top: 10px;
  color: #a6a6a6;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
`;

const TitleSteps = styled.p`
  margin-top: -10px;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #02a1b5;
`;

const EmptyCart = styled.div`
  height: 50vh;
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #45494a;
    margin-bottom: 40px;
  }
`;

const WhiteButton = styled.button`
  width: 214px;
  margin: 6px auto;
  height: 48px;
  background: none;
  color: #8055ad;
  border: 2px solid #8055ad;
  border-radius: 24px;
  font-size: 16px;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: #8055ad;
  }
`;

const NotCompatibleItems = styled(Container)`
  h1 {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 150%;
    color: #000000;
    text-align: center;
    width: auto;
    max-width: 447px;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

const shopingCart = {
  items: [
    {
      promo_price: 236.36,
      categoria: 4,
      indicaciones: <p>8 a 12 horas de ayuno.</p>,
      id: 14,
      normal_price: 260,
      estudios: [
        {
          name: "Citometría Hemática",
          id: 1359,
        },
      ],
      muestra: <p>Sangre.</p>,
      price: 236,
      indications: null,
      promocion: 1,
      howMany: 1,
      detalleCategoria: {
        imagen_mobile: "uploadC/laboratorio-clinico-desktop-212212561537.png",
        descripcion_larga: null,
        descripcion:
          "Servicio de análisis eficiente, innovador y de confianza para la detección, tratamiento y prevención oportuna de patologías.",
        created_at: "2021-12-22 15:37:56",
        elim: 0,
        imagen_desktop: "uploadC/laboratorio-clinico-mobile-212212561537.png",
        nombre: "Laboratorio clínico",
        link: "laboratorio-clinico",
        icono: "https://lpqsystem.com/labopat/logos/lab-clinicoe.png",
        id: 4,
        updated_at: "2021-12-22 15:37:56",
      },
      title: "Citometría hemática",
      name: "Citometría hemática",
      oldPrice: 260,
      tEntrega: <p>12 a 24 horas.</p>,
    },
    {
      promo_price: 430.65,
      tEntrega: <p>24 a 48 horas.</p>,
      normal_price: 478.5,
      title: "Hemoglobina glucosilada HbA1c",
      name: "Hemoglobina glucosilada HbA1c",
      price: 430,
      howMany: 1,
      detalleCategoria: {
        imagen_mobile: "uploadC/laboratorio-clinico-desktop-212212561537.png",
        created_at: "2021-12-22 15:37:56",
        nombre: "Laboratorio clínico",
        updated_at: "2021-12-22 15:37:56",
        link: "laboratorio-clinico",
        icono: "https://lpqsystem.com/labopat/logos/lab-clinicoe.png",
        descripcion_larga: null,
        imagen_desktop: "uploadC/laboratorio-clinico-mobile-212212561537.png",
        id: 4,
        descripcion:
          "Servicio de análisis eficiente, innovador y de confianza para la detección, tratamiento y prevención oportuna de patologías.",
        elim: 0,
      },
      muestra: <p>Sangre total.</p>,
      indicaciones: <p>Este estudio no requiere de ayuno previo.</p>,
      promocion: 1,
      indications: null,
      id: 132,
      categoria: 4,
      estudios: [
        {
          id: 1399,
          name: "Hemoglobina Glucosilada_referencia",
        },
      ],
      oldPrice: 478,
    },
    {
      promo_price: 876.96,
      tEntrega: <p>11&nbsp;d&iacute;as h&aacute;biles.</p>,
      oldPrice: 975,
      howMany: 1,
      categoria: 4,
      price: 876,
      muestra: <p>Sangre total.&nbsp;</p>,
      detalleCategoria: {
        imagen_mobile: "uploadC/laboratorio-clinico-desktop-212212561537.png",
        nombre: "Laboratorio clínico",
        imagen_desktop: "uploadC/laboratorio-clinico-mobile-212212561537.png",
        elim: 0,
        id: 4,
        updated_at: "2021-12-22 15:37:56",
        icono: "https://lpqsystem.com/labopat/logos/lab-clinicoe.png",
        descripcion:
          "Servicio de análisis eficiente, innovador y de confianza para la detección, tratamiento y prevención oportuna de patologías.",
        link: "laboratorio-clinico",
        created_at: "2021-12-22 15:37:56",
        descripcion_larga: null,
      },
      id: 151,
      name: "Hemocultivo",
      estudios: [
        {
          id: 1591,
          name: "Hemocultivo_ref",
        },
      ],
      promocion: 1,
      title: "Hemocultivo",
      normal_price: 975,
      indications: null,
      indicaciones: (
        <p>
          Este estudio no requiere de&nbsp;ayuno previo. No se recomienda estar
          bajo tratamiento con antibi&oacute;ticos a menos que haya
          indicaci&oacute;n espec&iacute;fica pr parte dle m&eacute;dico
          tratante.
        </p>
      ),
    },
  ],
  totalPrice: 1542,
};

export const CheckoutVG = () => {
  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(2);
  const logged = useSelector((state) => state.auth?.uid || false);
  const history = useHistory();

  const { uid } = useSelector((state) => state.user?.data?.data);
  const [cartItems, setCartItems] = useState([]);
  const [newNet, setNewNet] = useState(false);
  const user = useSelector((state) => state.user?.data?.data);
  const [loading, setLoading] = useState(false);
  const isDoctor =
    useSelector((state) => state.user?.data?.data.profile?.type) === 1;
  const firstTimeDoctor = useSelector(
    (state) => state.user?.data?.data.profile?.first_time
  );
  const [otherPersonCheck, setOtherPersonCheck] = useState(true);
  const [otherPerson, setOtherPerson] = useState({});
  const [stepsTitle, setStepsTitle] = useState([
    // 'Verifica tu lista de estudios',
    cartItems.some((e) => (e === e.id) === 675 || e.id === 966)
      ? "Información"
      : "Envío",
    "Pago",
    "Confirmación",
  ]);
  const [stepsIcon, setStepsIcon] = useState([
    <UilTruck />,
    <UilClipboardNotes />,
  ]);

  // Check if is order to patient
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var isOrder = urlParams.get("doctor") === "1";

  // We get window width
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let topNav = document.getElementById("topNavBar");

  // useEffect(() => {
  //   step === 1 && setCartItems(shoppingCart.items);
  //   window.scroll({
  //     top: -10,
  //   });
  // }, [shoppingCart.items]);

  // step one
  const [pay, setPay] = useState("web");
  const [subTotal, setSubTotal] = useState(0);
  const [subTotalWeb, setSubTotalWeb] = useState(0);
  const [subTotalSuc, setSubTotalSuc] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [couponID, setCouponID] = useState(0);
  const [couponClient, setCouponClient] = useState(83);
  const [isExpress, setIsExpress] = useState(true);

  // step two
  const [date, setDate] = useState();
  const [place, setPlace] = useState();
  const [time, setTime] = useState();
  const [free, setFree] = useState(false);

  let completeName = `${user?.name}${
    user?.fathersLastname ? ` ${user?.fathersLastname}` : ""
  }${user?.mothersLastname ? ` ${user?.mothersLastname}` : ""}`;

  // step three
  const [personalData, setPersonalData] = useState({
    name: completeName,
    secondName: "",
    lastName: "",
    phone: user?.phone,
    email: user?.email,
    birthday: "",
    cp: "",
    state: "",
    city: "",
    colonia: "",
    street: "",
  });

  const [confirm, setConfirm] = useState({
    folio: "",
    id: 0,
    ok: true,
    type: 0,
  });

  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker("Checkout");
  var isOrder = urlParams.get("doctor");
  const doctorIdlpq = urlParams.get("docId");
  var patientOrderId = urlParams.get("patient");
  var isSucursal = urlParams.get("sucursal");
  var orderID = urlParams.get("order");

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const cartId = localStorage.getItem("cartId");
      const endpoint =
        "https://labopat-5792e.appspot.com/get-cart/?id=" + cartId;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      let request = await axios.get(endpoint, options);
      setCartItems(request.data.cart.items);
      const pricesArray = request.data.cart.items.map((item) => item.price);
      const final = pricesArray.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      if (request.data.cart.items.some((e) => e.id === 675 || e.id === 966)) {
        setStepsTitle(["Información", "Pago", "Confirmación"]);
      }
      setSubTotalWeb(final);
      setLoading(false);
    }
    try {
      getData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    var isOrder = urlParams.get("doctor");
    // setStep(2);
    const pricesArray = cartItems.map((item) => item.price);
    const final = pricesArray.reduce((a, b) => a + b, 0);
    const oldPricesArray = cartItems.map((item) => item.oldPrice);
    const oldFinal = oldPricesArray.reduce((a, b) => a + b, 0);
    setSubTotalWeb(final);
    setSubTotalSuc(oldFinal);
    if (isSucursal === "1") {
      setPay("sucursal");
    }
    if (isOrder === "1") {
      setPay("orderToPatient");
      if (isDoctor && firstTimeDoctor) {
        setTotalSteps(3);
        setStepsTitle([
          // 'Verifica tu lista de estudios',
          "",
          "Carpeta médica",
          "Expediente",
        ]);
        setStepsIcon([
          // <UilClipboardNotes />,
          <UilCalendarAlt />,
          <UilUser />,
          <UilUser />,
        ]);
      } else if (isDoctor && !firstTimeDoctor) {
        setTotalSteps(1);
        setStepsTitle([
          // 'Verifica tu lista de estudios',
          // '',
          "Expediente",
        ]);
        setStepsIcon([
          // <UilClipboardNotes />,
          <UilUser />,
        ]);
      }
    }
    // Setting total steps and icons
    if (!isOrder && !isSucursal) {
      setTotalSteps(3);
      setStepsTitle([
        // 'Verifica tu lista de estudios',
        "Expediente",
        "Pago",
        "Confirmación",
      ]);
      setStepsIcon([
        // <UilClipboardNotes />,
        <UilTruck />,
        <UilClipboardNotes />,
      ]);
    } else if (isSucursal) {
      setTotalSteps(2);
      setStepsTitle([
        // 'Verifica tu lista de estudios',
        "Expediente",
        "Confirmación",
      ]);
      setStepsIcon([<UilClipboardNotes />, <UilCalendarAlt />, <UilUser />]);
    } else if (isOrder) {
      if (isDoctor && firstTimeDoctor) {
        setTotalSteps(3);
        setStepsTitle([
          // 'Verifica tu lista de estudios',
          "",
          "Carpeta médica",
          "Expediente",
        ]);
        setStepsIcon([
          // <UilClipboardNotes />,
          <UilCalendarAlt />,
          <UilUser />,
          <UilUser />,
        ]);
      } else if (isDoctor && !firstTimeDoctor) {
        setTotalSteps(4);
        setStepsTitle(["Genera una orden", "Datos del paciente", "Expediente"]);
        setStepsIcon([
          // <UilClipboardNotes />,
          <UilCalendarAlt />,
          <UilUser />,
        ]);
      }
    }
  }, []);

  const altaClientes = async (
    persona,
    check,
    fromRed,
    newNet,
    tipo,
    idPago
  ) => {
    if (cartItems?.length > 0) {
      await setLoading(true);
      var elTotal = 0;
      // console.log('*****************************');
      var tmpNewRed = {};
      if (fromRed?.id) {
        tmpNewRed = fromRed;
      }

      // console.log('persona ', persona);
      //return;
      // console.log('*****************************');
      var formData = new FormData();
      //personal data
      formData.append("uid", logged);
      formData.append("dx", persona.dx);
      formData.append("name", persona.name);
      formData.append("app", persona.fathersLastname);
      formData.append("apm", persona.mothersLastname);
      formData.append("email", persona.email);
      formData.append("sexo", "NE");
      formData.append("tipo", persona.shipping);
      // formData.append('idPago', idPago);
      formData.append(
        "fnac",
        persona.year + "-" + persona.month + "-" + persona.day
      );
      formData.append("phone", persona.phone);

      formData.append("city", persona.city);
      formData.append("colonia", persona.colonia);
      formData.append("cp", persona.cp);
      formData.append("indications", persona.indications);
      formData.append("next", persona.next);
      formData.append("nint", persona.nint);
      formData.append("shipping", persona.shipping);
      formData.append("state", persona.state);
      formData.append("street", persona.street);
      formData.append("street2", persona.street2);
      formData.append("street3", persona.street3);
      //red
      formData.append("newNetwork", newNet ? 1 : 0);
      formData.append("idNetwork", newNet ? 0 : fromRed?.id);
      formData.append("otherPerson", persona.otherPerson);
      //studies
      cartItems.forEach((element) => {
        var tmp = "";
        var ids = [];
        element.estudios.forEach((elem) => {
          formData.append("products[]", element.id);
          formData.append("productTitle[]", element.title);
          if (pay === "web" || pay === "orderToPatient") {
            formData.append("studiesPrice[]", element.price);
            if (!ids.includes(element.id)) {
              ids.push(element.id);
              elTotal += parseFloat(element.price);
            }
          } else {
            formData.append("studiesPrice[]", element.oldPrice);
            if (!ids.includes(element.id)) {
              ids.push(element.id);
              elTotal += parseFloat(element.oldPrice);
            }
          }
          formData.append("studies[]", elem.id);
        });
        formData.append("howMany", parseInt(element.howMany));
        let childrens = localStorage.getItem(`childsFor-${element.id}`);
        if (childrens !== null && childrens <= element.howMany) {
          formData.append("childrens", parseInt(childrens));
        } else {
          formData.append("childrens", parseInt(0));
        }
        //
      });
      //place
      formData.append("place", place?.id ? place?.id : 1);
      //date and hour
      if (pay === "orderToPatient") {
        formData.append("date", "2022-11-07");
        formData.append("time", "08:10");
      } else {
        //var tmpTime = time.split(' - ')
        const date = new Date();
        console.log(date); 
        formData.append("time", "07:20");
        formData.append("date", date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate());
      }

      //payment
      var pagado = subTotalWeb;
      if (isNaN(pagado)) {
        let tmp = checkPrices();
        pagado = pay === "web" || pay === "orderToPatient" ? tmp[0] : tmp[0];
      }

      formData.append("subTotal", pagado);

      formData.append("discount", JSON.stringify(discount));
      formData.append("cuponCode", coupon);
      formData.append("cupon", couponApplied ? 1 : 0);
      if (pay === "web") {
        formData.append("paymentType", 1);
        formData.append("refOrdenMedico", "");
      } else if (pay === "sucursal") {
        formData.append("paymentType", 2);
        formData.append("refOrdenMedico", "");
      } else {
        //orden de paciente
        formData.append("paymentType", 3);
      }

      formData.append("orderType", pay);
      if (pay === "web") {
        formData.append("fase", 0);
      } else {
        formData.append("fase", 2);
      }

      // Medico tratante
      formData.append("medicoTratante", persona.medicoTratante);
      if (persona.medicoTratante === "1") {
        formData.append("nombreDoctor", persona.doctorName);
        formData.append(
          "emailDoctor",
          persona.doctorEmail ? persona.doctorEmail : ""
        );
        formData.append(
          "compartirResultados",
          persona.shareResults === "on" ? "1" : "0"
        );
      }

      //Billing info
      formData.append("necesitoFactura", parseInt(persona.needBilling));
      if (parseInt(persona.needBilling) === 1) {
        formData.append("rfc", persona.billing.rfc);
        formData.append("razon", persona.billing.name);
        formData.append("emailRFC", persona.billing.email);
        formData.append("regimen", persona.billing.regimen);
        formData.append("calle1", persona.billing.street);
        formData.append("next1", persona.billing.next);
        formData.append("nint1", persona.billing.nint);
        formData.append("cp1", persona.billing.cp);
        formData.append("colonia1", persona.billing.colonia);
        formData.append("municipio1", persona.billing.city);
        formData.append("estado1", persona.billing.state);
        formData.append("pais1", persona.billing.country);
        formData.append("usoFactura", persona.billing.usoFactura);
        formData.append("comentarioFactura", persona.billing.comments);
      }

      formData.append("order", orderID ?? "");

      // console.log('=====FORM DATA=====');
      // for (let item of formData.entries()) {
      //   console.log(`${item[0]}: ${item[1]}`);
      // }
      // console.log('=====END FORM DATA=====');
      // return;
      let response = await axios.post(
        orderID
          ? "https://clienteslabopat.com/updateOrden"
          : "https://clienteslabopat.com/alta-ecom-vg",
        formData
      );
      // console.log(response.data);
      if (response.data.ok) {
        await setConfirm(response);
        await setLoading(false);
        await setStep(step + 1);
        cartItems.map((item) => {
          localStorage.removeItem(`childsFor-${item.id}`);
          localStorage.removeItem(`is-${item.id}-checked`);
        });
      } else {
        window.alert(response.data.error);
      }
    } else {
      window.alert("No hay items en el carrito");
    }
  };

  const getInfo = async (id) => {
    console.log("get info");
    setLoading(true);
    try {
      //url vitalgene
      const appointment = await axios.get(
        `https://covid19-cc1fe.uc.r.appspot.com/get-order-detail?id=${id}`
      );
      if (appointment?.data.solicitud) {
        console.log("aqui 1");
        if (logged || !logged) {
          console.log("aqui 2");
          console.log(appointment.data.solicitud.nombre);

          await setPersonalData({
            name: appointment.data.solicitud.nombre,
            fathersLastname: appointment.data.solicitud.app,
            mothersLastname: appointment.data.solicitud.apm,
            phone: appointment.data.solicitud.telContacto,
            email: appointment.data.solicitud.emailContacto,
            birthday: appointment.data.solicitud.fnacimiento,
            gender: appointment.data.solicitud.genero,
            doctorId: appointment.data.doctorId,
            patientId: patientOrderId,
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderID) {
      getInfo(orderID);
    }
  }, []);

  const handleStepTwo = async (values) => {
    setPersonalData(values.personalData);
    if (free) {
      altaClientes(
        values.personalData,
        otherPersonCheck,
        otherPerson,
        newNet,
        3,
        ""
      );
    } else {
      continueClick();
    }
  };

  const handleStepThree = async (values) => {
    setPersonalData(values.personalData);
    setOtherPersonCheck(values.otherPersonCheck);
    setNewNet(values.newNetwork);
    //console.log(window.document.getElementById('gender').value);
    //return;
    setOtherPerson(values.otherPerson);
    console.log("TRYYYYY", values);
    if (pay === "web" && free) {
      console.log("-----");
      console.log(personalData);
      console.log("-----");
      altaClientes(
        values.personalData,
        values.otherPersonCheck,
        values.otherPerson,
        values.newNetwork,
        3,
        ""
      );
      gaEventTracker("finalizar-pago-online-free");
    } else if (pay === "sucursal") {
      altaClientes(
        values.personalData,
        values.otherPersonCheck,
        values.otherPerson,
        values.newNetwork,
        1,
        ""
      );
      gaEventTracker("finalizar-pago-sucursal");
    } else if (pay === "orderToPatient") {
      console.log("es orden");
      altaClientes(
        values.personalData,
        values.otherPersonCheck,
        values.otherPerson,
        values.newNetwork,
        2,
        ""
      );
      gaEventTracker("finalizar-orden-medica");
    } else {
      setStep(step + 1);
    }
  };

  const handleStepFour = (sol) => {
    // console.log('Payment Intent: ', sol.data);
    // console.log(sol);
    if (sol?.data?.id) {
      altaClientes(
        personalData,
        otherPersonCheck,
        otherPerson,
        newNet,
        3,
        sol?.data?.id
      );
      gaEventTracker("finalizar-pago-online");
    } else {
      window.alert("No se puso procesar el pago intenta nuevamente");
    }
    // setConfirm(sol);
  };

  const handleFirstTimeDoctor = (values) => {
    setPersonalData(values.personalData);
    //console.log(personalData);
    setStep(step + 1);
  };

  const continueClick = () => {
    setStep(step + 1);
    window.scroll({
      top: -10,
    });
  };

  const applyCupon = (cupon, items, cFree) => {
    // console.log(cupon, items);
    setCouponApplied(true);
    setCoupon(cupon);
    const pricesArray = items.map((item) => item.precio_normal);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setDiscount(
      (cartItems.some((e) => e.id === 675 || e.id === 966)
        ? subTotalWeb
        : subTotalWeb - 350) - final
    );
    setSubTotalWeb(
      cartItems.some((e) => e.id === 675 || e.id === 966) ? final : final + 350
    );
    console.log("🚀 ~ file: Checkout.jsx:730 ~ applyCupon ~ cFree", cFree);
    if (
      pay === "web" &&
      (cFree === 1 ||
        (final === 0 && cartItems.some((e) => e.id === 675 || e.id === 966)))
    ) {
      setFree(true);
      setTotalSteps(3);
      setStepsTitle(["Cita", "Expediente"]);
      setStepsIcon([<UilCalendarAlt />, <UilUser />]);
    }
  };

  const checkPrices = () => {
    const pricesArray = cartItems.map((item) => item.price);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    return [final];
  };

  useEffect(() => {
    if (isNaN(subTotalWeb)) {
      let prices = checkPrices();
      setSubTotalWeb(prices[0]);
      // console.log(prices[0]);
    }
  }, [cartItems]);


  return (
    <>
      <Progress style={{ top: topNav?.clientHeight?.doctorHeight }}>
        {width < 576 ? (
          <Container>
            <Row style={{ marginTop: "10px", display: "flex" }}>
              <Col
                xs={2}
                sm={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <UilClipboardNotes>{stepsIcon[step - 1]}</UilClipboardNotes>
              </Col>
              <Col xs={10} sm={10}>
                <TextSteps>
                  Paso {step} de {totalSteps}
                </TextSteps>
                <TitleSteps>{stepsTitle[step - 1]}</TitleSteps>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row style={{ marginTop: "10px", display: "flex" }}>
              {stepsTitle.map((title, index) =>
                title !== "" ? (
                  <Col
                    sm={
                      12 /
                      (pay === "orderToPatient" ? totalSteps - 1 : totalSteps)
                    }
                    style={{
                      filter: step < index + 1 ? "grayscale()" : "none",
                    }}
                    key={index}
                  >
                    <Row>
                      <Col
                        xs={2}
                        className="d-flex flex-column justify-content-center align-items-center"
                      >
                        <UilClipboardNotes color="#3ECADD">
                          {stepsIcon[index]}
                        </UilClipboardNotes>
                      </Col>
                      <Col xs={10}>
                        <TextSteps>
                          Paso{" "}
                          {pay === "orderToPatient" && index > 2
                            ? index
                            : index + 1}{" "}
                          de{" "}
                          {pay === "orderToPatient"
                            ? totalSteps - 1
                            : totalSteps}
                        </TextSteps>
                        <TitleSteps>{title}</TitleSteps>
                      </Col>
                    </Row>
                  </Col>
                ) : null
              )}
            </Row>
          </Container>
        )}
      </Progress>
      {loading ? <Loader show={loading} /> : null}
      {cartItems?.length !== 0 ? (     
          <Background isDoctor={isDoctor}>
            <Container style={{ paddingLeft: "16px", paddingRight: "16px" }}>
              {step === 1 ? (
                pay !== "orderToPatient" ? (
                  <StepThree
                    onStepThree={handleStepThree}
                    personalData={personalData}
                    isDoctor={isDoctor}
                    pay={pay}
                    user={user}
                  />
                ) : (
                  setStep(2)
                )
              ) : step === 2 ? (
                firstTimeDoctor && pay === "orderToPatient" ? (
                  <FirstTimeDoctor
                    personalData={personalData}
                    onFirstTimeDoctor={handleFirstTimeDoctor}
                  />
                ) : pay === "web" && !free ? (
                  <StepFour
                    items={cartItems}
                    coupon={coupon}
                    discount={discount}
                    subTotal={subTotalWeb}
                    onStepFour={handleStepFour}
                    personalData={personalData}
                    isExpress={isExpress}
                  />
                ) : (
                  <StepThree
                    onStepThree={handleStepThree}
                    personalData={personalData}
                    isDoctor={isDoctor}
                    pay={pay}
                    user={user}
                  />
                )
              ) : step === 3 ? (
                firstTimeDoctor && pay === "orderToPatient" ? (
                  <StepThree
                    onStepThree={handleStepThree}
                    personalData={personalData}
                    isDoctor={isDoctor}
                    pay={pay}
                    user={user}
                  />
                ) : isDoctor && pay === "orderToPatient" ? (
                  <ThanksScreen
                    items={cartItems}
                    personalData={personalData}
                    isDoctor={isDoctor}
                    pay={pay}
                    coupon={coupon}
                    discount={discount}
                    total={subTotalWeb}
                    otherPerson={otherPersonCheck}
                    firstTimeDoctor={firstTimeDoctor}
                    confirm={confirm}
                  />
                ) : (
                  <ThanksScreenCom
                    items={cartItems}
                    personalData={personalData}
                    pay={pay}
                    coupon={coupon}
                    discount={discount}
                    total={subTotalWeb}
                    otherPerson={otherPersonCheck}
                    firstTimeDoctor={firstTimeDoctor}
                    confirm={confirm}
                  />
                )
              ) : (
                <ThanksScreen
                  items={cartItems}
                  personalData={personalData}
                  isDoctor={isDoctor}
                  pay={pay}
                  coupon={coupon}
                  discount={discount}
                  total={subTotalWeb}
                  otherPerson={otherPersonCheck}
                  firstTimeDoctor={firstTimeDoctor}
                  confirm={confirm}
                />
              )}
            </Container>
          </Background>
      ) : (
        <EmptyCart>
          <div id="empty">
            <UilClipboardAlt color="#A6A6A6" size={100} />
          </div>
          <p>No hay estudios agregados</p>
          {/*<div className="d-flex align-items-center">
            <WhiteButton onClick={() => history.push("/laboratorio-clinico")}>
              Buscar más estudios
            </WhiteButton>
      </div>*/}
        </EmptyCart>
      )}
    </>
    // TODO: Delete the items from the cart when flow ends
  );
};
